// margin/padding utilities
$mpSizes: 0, 2, 4, 5, 6, 8, 10, 12, 14, 15, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
@each $size in $mpSizes {
  .m-#{$size} {
    margin: #{$size}px
  }
	.mb-#{$size} {
    margin-bottom: #{$size}px
  }
	.mt-#{$size} {
    margin-top: #{$size}px
  }
	.mr-#{$size} {
    margin-right: #{$size}px
  }
	.ml-#{$size} {
    margin-left: #{$size}px
  }
	.p-#{$size} {
    padding: #{$size}px
  }
	.pb-#{$size} {
    padding-bottom: #{$size}px
  }
	.pt-#{$size} {
    padding-top: #{$size}px
  }
	.pr-#{$size} {
    padding-right: #{$size}px
  }
	.pl-#{$size} {
    padding-left: #{$size}px
  }
}

// TEXT UTILITIES
$textSizes: 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44, 48, 52;
@each $size in $textSizes {
	.text-#{$size} {
		font-size: #{$size}px
  }
}

.text-white {
	color: #fff
}

.note {
	font-size: 0.9em;
	color: #818181;
}

.text-center {
	text-align: center
}

.text-right {
	text-align: right
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
	font-weight: bold;
}

.v-center{
	vertical-align: middle;
}

.pointer {
	cursor: pointer;
}

// FLEX UTILITIES
$justifyTypes: center, flex-start, flex-end, space-between, space-around, space-evenly;
@each $type in $justifyTypes {
	.justify-#{$type} {
		justify-content: #{$type};
  }
}
$alignTypes: flex-start, flex-end, center, stretch, baseline, space-between, space-around;
@each $type in $justifyTypes {
	.align-#{$type} {
		align-items: #{$type};
  }
}


// general utilities
.inline-block {
	display: inline-block
}
.block {
	display: block
}
.bg-white {
	background-color: #fff;
}

.favIcon{
	font-size: 22px;
	line-height: 43px;
	color: #E11646;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.cpBgColor {
	background-color: #112211;
}

.btn-xs {
	padding: 3px 5px !important;
}
